import Form from 'react-bootstrap/Form'
import './FASH.css'
function App() {
    return (
        <div className="Apppt">
            
        </div>

    );
}
export default App;