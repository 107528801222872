/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createInternForm = /* GraphQL */ `
  mutation CreateInternForm(
    $input: CreateInternFormInput!
    $condition: ModelInternFormConditionInput
  ) {
    createInternForm(input: $input, condition: $condition) {
      id
      Student_Name
      W_Num
      Student_Cell_Num
      Student_Home_Phone
      Student_Work_Number
      Student_Present_Address
      Student_Permanent_Address
      Student_Email
      Degree
      Semester_Hours_Earned
      Major_GPA
      Expected_Grad_Date
      Company_Name
      Company_Address
      Name_and_Title_Immediate_Supervisor
      Supervisor_Email
      Supervisor_Phone
      Proposed_Start
      Proposed_End
      Employed_Full
      Job_Title
      Job_Responsibilities
      Student_Signature_Form_401
      Student_Signature_Date_Form_401
      Date_Rec_Dep
      Action_Taken
      Reason
      Faculty_Signature_Form_401
      Faculty_Signature_Date_Form_401
      Dep_Head_Signature_Form_401
      Dep_Head_Signature_Date_Form_401
      Dean_Signature_Form_401
      Dean_Signature_Date_Form_401
      Semester_And_Year
      Hours_Per_Week
      Employer_Signature_Form_402
      Employer_City
      Employer_State
      Employer_Zip
      Student_Signature_Form_402
      Student_Signature_Date_Form_402
      Student_Will_Accomplish_1
      Supervisor_Rating_1
      Student_Will_Accomplish_2
      Supervisor_Rating_2
      Student_Will_Accomplish_3
      Supervisor_Rating_3
      Student_Signature_Form_403
      Student_Signature_Date_Form_403
      Employer_Signature_Form_403
      Employer_Signature_Date_Form_403
      Intern_Cord_Signature_Form_403
      Intern_Cord_Signature_Date_Form_403
      Total_Hours_For_Semester
      Total_Wages_For_Deduction
      Month_1
      Month_1_Week_1
      Month_1_Week_1_Sun
      Month_1_Week_1_Mon
      Month_1_Week_1_Tue
      Month_1_Week_1_Wed
      Month_1_Week_1_Thu
      Month_1_Week_1_Fri
      Month_1_Week_1_Sat
      Month_1_Week_1_Total_Hours
      Month_1_Week_1_Weekly_Wages
      Month_1_Week_2
      Month_1_Week_2_Sun
      Month_1_Week_2_Mon
      Month_1_Week_2_Tue
      Month_1_Week_2_Wed
      Month_1_Week_2_Thu
      Month_1_Week_2_Fri
      Month_1_Week_2_Sat
      Month_1_Week_2_Total_Hours
      Month_1_Week_2_Weekly_Wages
      Month_1_Week_3
      Month_1_Week_3_Sun
      Month_1_Week_3_Mon
      Month_1_Week_3_Tue
      Month_1_Week_3_Wed
      Month_1_Week_3_Thu
      Month_1_Week_3_Fri
      Month_1_Week_3_Sat
      Month_1_Week_3_Total_Hours
      Month_1_Week_3_Weekly_Wages
      Month_1_Week_4
      Month_1_Week_4_Sun
      Month_1_Week_4_Mon
      Month_1_Week_4_Tue
      Month_1_Week_4_Wed
      Month_1_Week_4_Thu
      Month_1_Week_4_Fri
      Month_1_Week_4_Sat
      Month_1_Week_4_Total_Hours
      Month_1_Week_4_Weekly_Wages
      Month_1_Total_Sun
      Month_1_Total_Mon
      Month_1_Total_Tue
      Month_1_Total_Wed
      Month_1_Total_Thu
      Month_1_Total_Fri
      Month_1_Total_Sat
      Month_1_Total_Hours
      Month_1_Total_Weekly_Wages
      Month_2
      Month_2_Week_1
      Month_2_Week_1_Sun
      Month_2_Week_1_Mon
      Month_2_Week_1_Tue
      Month_2_Week_1_Wed
      Month_2_Week_1_Thu
      Month_2_Week_1_Fri
      Month_2_Week_1_Sat
      Month_2_Week_1_Total_Hours
      Month_2_Week_1_Weekly_Wages
      Month_2_Week_2
      Month_2_Week_2_Sun
      Month_2_Week_2_Mon
      Month_2_Week_2_Tue
      Month_2_Week_2_Wed
      Month_2_Week_2_Thu
      Month_2_Week_2_Fri
      Month_2_Week_2_Sat
      Month_2_Week_2_Total_Hours
      Month_2_Week_2_Weekly_Wages
      Month_2_Week_3
      Month_2_Week_3_Sun
      Month_2_Week_3_Mon
      Month_2_Week_3_Tue
      Month_2_Week_3_Wed
      Month_2_Week_3_Thu
      Month_2_Week_3_Fri
      Month_2_Week_3_Sat
      Month_2_Week_3_Total_Hours
      Month_2_Week_3_Weekly_Wages
      Month_2_Week_4
      Month_2_Week_4_Sun
      Month_2_Week_4_Mon
      Month_2_Week_4_Tue
      Month_2_Week_4_Wed
      Month_2_Week_4_Thu
      Month_2_Week_4_Fri
      Month_2_Week_4_Sat
      Month_2_Week_4_Total_Hours
      Month_2_Week_4_Weekly_Wages
      Month_2_Total_Sun
      Month_2_Total_Mon
      Month_2_Total_Tue
      Month_2_Total_Wed
      Month_2_Total_Thu
      Month_2_Total_Fri
      Month_2_Total_Sat
      Month_2_Total_Hours
      Month_2_Total_Weekly_Wages
      Month_3
      Month_3_Week_1
      Month_3_Week_1_Sun
      Month_3_Week_1_Mon
      Month_3_Week_1_Tue
      Month_3_Week_1_Wed
      Month_3_Week_1_Thu
      Month_3_Week_1_Fri
      Month_3_Week_1_Sat
      Month_3_Week_1_Total_Hours
      Month_3_Week_1_Weekly_Wages
      Month_3_Week_2
      Month_3_Week_2_Sun
      Month_3_Week_2_Mon
      Month_3_Week_2_Tue
      Month_3_Week_2_Wed
      Month_3_Week_2_Thu
      Month_3_Week_2_Fri
      Month_3_Week_2_Sat
      Month_3_Week_2_Total_Hours
      Month_3_Week_2_Weekly_Wages
      Month_3_Week_3
      Month_3_Week_3_Sun
      Month_3_Week_3_Mon
      Month_3_Week_3_Tue
      Month_3_Week_3_Wed
      Month_3_Week_3_Thu
      Month_3_Week_3_Fri
      Month_3_Week_3_Sat
      Month_3_Week_3_Total_Hours
      Month_3_Week_3_Weekly_Wages
      Month_3_Week_4
      Month_3_Week_4_Sun
      Month_3_Week_4_Mon
      Month_3_Week_4_Tue
      Month_3_Week_4_Wed
      Month_3_Week_4_Thu
      Month_3_Week_4_Fri
      Month_3_Week_4_Sat
      Month_3_Week_4_Total_Hours
      Month_3_Week_4_Weekly_Wages
      Month_3_Total_Sun
      Month_3_Total_Mon
      Month_3_Total_Tue
      Month_3_Total_Wed
      Month_3_Total_Thu
      Month_3_Total_Fri
      Month_3_Total_Sat
      Month_3_Total_Hours
      Month_3_Total_Weekly_Wages
      Month_4
      Month_4_Week_1
      Month_4_Week_1_Sun
      Month_4_Week_1_Mon
      Month_4_Week_1_Tue
      Month_4_Week_1_Wed
      Month_4_Week_1_Thu
      Month_4_Week_1_Fri
      Month_4_Week_1_Sat
      Month_4_Week_1_Total_Hours
      Month_4_Week_1_Weekly_Wages
      Month_4_Week_2
      Month_4_Week_2_Sun
      Month_4_Week_2_Mon
      Month_4_Week_2_Tue
      Month_4_Week_2_Wed
      Month_4_Week_2_Thu
      Month_4_Week_2_Fri
      Month_4_Week_2_Sat
      Month_4_Week_2_Total_Hours
      Month_4_Week_2_Weekly_Wages
      Month_4_Week_3
      Month_4_Week_3_Sun
      Month_4_Week_3_Mon
      Month_4_Week_3_Tue
      Month_4_Week_3_Wed
      Month_4_Week_3_Thu
      Month_4_Week_3_Fri
      Month_4_Week_3_Sat
      Month_4_Week_3_Total_Hours
      Month_4_Week_3_Weekly_Wages
      Month_4_Week_4
      Month_4_Week_4_Sun
      Month_4_Week_4_Mon
      Month_4_Week_4_Tue
      Month_4_Week_4_Wed
      Month_4_Week_4_Thu
      Month_4_Week_4_Fri
      Month_4_Week_4_Sat
      Month_4_Week_4_Total_Hours
      Month_4_Week_4_Weekly_Wages
      Month_4_Total_Sun
      Month_4_Total_Mon
      Month_4_Total_Tue
      Month_4_Total_Wed
      Month_4_Total_Thu
      Month_4_Total_Fri
      Month_4_Total_Sat
      Month_4_Total_Hours
      Month_4_Total_Weekly_Wages
      Student_Signature_Form_404
      Student_Signature_Date_Form_404
      Supervisor_Signature_Form_404
      Supervisor_Signature_Date_Form_404
      Date_Form_420
      Relations_With_Others
      Attitude_Application_To_Work
      Judgement
      Dependability
      Ability_To_Learn
      Quality_Of_Work
      Overall_Performance
      Attendance
      Punctuality
      Remarks
      Report_Discussed_With_Student
      Student_Signature_Form_420
      Student_Signature_Date_Form_420
      Supervisor_Signature_Form_420
      Supervisor_Signature_Date_Form_420
      Supervisor_Name
      Rating_Of_Employer
      Rating_Of_Work_Exp
      Relation_To_CMPS_Studies
      Rating_Of_Learning_Of_Internship
      Would_You_Repeat
      Would_You_Repeat_Explain
      Would_You_Recommend
      Would_You_Recommend_Explain
      Additional_Content_For_Curriculum
      Any_Additional_Comments
      Student_Signature_Form_405
      Student_Signature_Date_Form_405
      Week_1
      Week_1_Activities
      Week_1_Hours
      Week_2
      Week_2_Activities
      Week_2_Hours
      Week_3
      Week_3_Activities
      Week_3_Hours
      Week_4
      Week_4_Activities
      Week_4_Hours
      Week_5
      Week_5_Activities
      Week_5_Hours
      Week_6
      Week_6_Activities
      Week_6_Hours
      Week_7
      Week_7_Activities
      Week_7_Hours
      Total_Hours
      Form_401
      Form_402
      Form_403
      Form_404
      Form_405
      Form_420
      Form_410
      sup_sig_date_form_402
      compl_sup_sig_form_403
      compl_sup_sig_date_form_403
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInternForm = /* GraphQL */ `
  mutation UpdateInternForm(
    $input: UpdateInternFormInput!
    $condition: ModelInternFormConditionInput
  ) {
    updateInternForm(input: $input, condition: $condition) {
      id
      Student_Name
      W_Num
      Student_Cell_Num
      Student_Home_Phone
      Student_Work_Number
      Student_Present_Address
      Student_Permanent_Address
      Student_Email
      Degree
      Semester_Hours_Earned
      Major_GPA
      Expected_Grad_Date
      Company_Name
      Company_Address
      Name_and_Title_Immediate_Supervisor
      Supervisor_Email
      Supervisor_Phone
      Proposed_Start
      Proposed_End
      Employed_Full
      Job_Title
      Job_Responsibilities
      Student_Signature_Form_401
      Student_Signature_Date_Form_401
      Date_Rec_Dep
      Action_Taken
      Reason
      Faculty_Signature_Form_401
      Faculty_Signature_Date_Form_401
      Dep_Head_Signature_Form_401
      Dep_Head_Signature_Date_Form_401
      Dean_Signature_Form_401
      Dean_Signature_Date_Form_401
      Semester_And_Year
      Hours_Per_Week
      Employer_Signature_Form_402
      Employer_City
      Employer_State
      Employer_Zip
      Student_Signature_Form_402
      Student_Signature_Date_Form_402
      Student_Will_Accomplish_1
      Supervisor_Rating_1
      Student_Will_Accomplish_2
      Supervisor_Rating_2
      Student_Will_Accomplish_3
      Supervisor_Rating_3
      Student_Signature_Form_403
      Student_Signature_Date_Form_403
      Employer_Signature_Form_403
      Employer_Signature_Date_Form_403
      Intern_Cord_Signature_Form_403
      Intern_Cord_Signature_Date_Form_403
      Total_Hours_For_Semester
      Total_Wages_For_Deduction
      Month_1
      Month_1_Week_1
      Month_1_Week_1_Sun
      Month_1_Week_1_Mon
      Month_1_Week_1_Tue
      Month_1_Week_1_Wed
      Month_1_Week_1_Thu
      Month_1_Week_1_Fri
      Month_1_Week_1_Sat
      Month_1_Week_1_Total_Hours
      Month_1_Week_1_Weekly_Wages
      Month_1_Week_2
      Month_1_Week_2_Sun
      Month_1_Week_2_Mon
      Month_1_Week_2_Tue
      Month_1_Week_2_Wed
      Month_1_Week_2_Thu
      Month_1_Week_2_Fri
      Month_1_Week_2_Sat
      Month_1_Week_2_Total_Hours
      Month_1_Week_2_Weekly_Wages
      Month_1_Week_3
      Month_1_Week_3_Sun
      Month_1_Week_3_Mon
      Month_1_Week_3_Tue
      Month_1_Week_3_Wed
      Month_1_Week_3_Thu
      Month_1_Week_3_Fri
      Month_1_Week_3_Sat
      Month_1_Week_3_Total_Hours
      Month_1_Week_3_Weekly_Wages
      Month_1_Week_4
      Month_1_Week_4_Sun
      Month_1_Week_4_Mon
      Month_1_Week_4_Tue
      Month_1_Week_4_Wed
      Month_1_Week_4_Thu
      Month_1_Week_4_Fri
      Month_1_Week_4_Sat
      Month_1_Week_4_Total_Hours
      Month_1_Week_4_Weekly_Wages
      Month_1_Total_Sun
      Month_1_Total_Mon
      Month_1_Total_Tue
      Month_1_Total_Wed
      Month_1_Total_Thu
      Month_1_Total_Fri
      Month_1_Total_Sat
      Month_1_Total_Hours
      Month_1_Total_Weekly_Wages
      Month_2
      Month_2_Week_1
      Month_2_Week_1_Sun
      Month_2_Week_1_Mon
      Month_2_Week_1_Tue
      Month_2_Week_1_Wed
      Month_2_Week_1_Thu
      Month_2_Week_1_Fri
      Month_2_Week_1_Sat
      Month_2_Week_1_Total_Hours
      Month_2_Week_1_Weekly_Wages
      Month_2_Week_2
      Month_2_Week_2_Sun
      Month_2_Week_2_Mon
      Month_2_Week_2_Tue
      Month_2_Week_2_Wed
      Month_2_Week_2_Thu
      Month_2_Week_2_Fri
      Month_2_Week_2_Sat
      Month_2_Week_2_Total_Hours
      Month_2_Week_2_Weekly_Wages
      Month_2_Week_3
      Month_2_Week_3_Sun
      Month_2_Week_3_Mon
      Month_2_Week_3_Tue
      Month_2_Week_3_Wed
      Month_2_Week_3_Thu
      Month_2_Week_3_Fri
      Month_2_Week_3_Sat
      Month_2_Week_3_Total_Hours
      Month_2_Week_3_Weekly_Wages
      Month_2_Week_4
      Month_2_Week_4_Sun
      Month_2_Week_4_Mon
      Month_2_Week_4_Tue
      Month_2_Week_4_Wed
      Month_2_Week_4_Thu
      Month_2_Week_4_Fri
      Month_2_Week_4_Sat
      Month_2_Week_4_Total_Hours
      Month_2_Week_4_Weekly_Wages
      Month_2_Total_Sun
      Month_2_Total_Mon
      Month_2_Total_Tue
      Month_2_Total_Wed
      Month_2_Total_Thu
      Month_2_Total_Fri
      Month_2_Total_Sat
      Month_2_Total_Hours
      Month_2_Total_Weekly_Wages
      Month_3
      Month_3_Week_1
      Month_3_Week_1_Sun
      Month_3_Week_1_Mon
      Month_3_Week_1_Tue
      Month_3_Week_1_Wed
      Month_3_Week_1_Thu
      Month_3_Week_1_Fri
      Month_3_Week_1_Sat
      Month_3_Week_1_Total_Hours
      Month_3_Week_1_Weekly_Wages
      Month_3_Week_2
      Month_3_Week_2_Sun
      Month_3_Week_2_Mon
      Month_3_Week_2_Tue
      Month_3_Week_2_Wed
      Month_3_Week_2_Thu
      Month_3_Week_2_Fri
      Month_3_Week_2_Sat
      Month_3_Week_2_Total_Hours
      Month_3_Week_2_Weekly_Wages
      Month_3_Week_3
      Month_3_Week_3_Sun
      Month_3_Week_3_Mon
      Month_3_Week_3_Tue
      Month_3_Week_3_Wed
      Month_3_Week_3_Thu
      Month_3_Week_3_Fri
      Month_3_Week_3_Sat
      Month_3_Week_3_Total_Hours
      Month_3_Week_3_Weekly_Wages
      Month_3_Week_4
      Month_3_Week_4_Sun
      Month_3_Week_4_Mon
      Month_3_Week_4_Tue
      Month_3_Week_4_Wed
      Month_3_Week_4_Thu
      Month_3_Week_4_Fri
      Month_3_Week_4_Sat
      Month_3_Week_4_Total_Hours
      Month_3_Week_4_Weekly_Wages
      Month_3_Total_Sun
      Month_3_Total_Mon
      Month_3_Total_Tue
      Month_3_Total_Wed
      Month_3_Total_Thu
      Month_3_Total_Fri
      Month_3_Total_Sat
      Month_3_Total_Hours
      Month_3_Total_Weekly_Wages
      Month_4
      Month_4_Week_1
      Month_4_Week_1_Sun
      Month_4_Week_1_Mon
      Month_4_Week_1_Tue
      Month_4_Week_1_Wed
      Month_4_Week_1_Thu
      Month_4_Week_1_Fri
      Month_4_Week_1_Sat
      Month_4_Week_1_Total_Hours
      Month_4_Week_1_Weekly_Wages
      Month_4_Week_2
      Month_4_Week_2_Sun
      Month_4_Week_2_Mon
      Month_4_Week_2_Tue
      Month_4_Week_2_Wed
      Month_4_Week_2_Thu
      Month_4_Week_2_Fri
      Month_4_Week_2_Sat
      Month_4_Week_2_Total_Hours
      Month_4_Week_2_Weekly_Wages
      Month_4_Week_3
      Month_4_Week_3_Sun
      Month_4_Week_3_Mon
      Month_4_Week_3_Tue
      Month_4_Week_3_Wed
      Month_4_Week_3_Thu
      Month_4_Week_3_Fri
      Month_4_Week_3_Sat
      Month_4_Week_3_Total_Hours
      Month_4_Week_3_Weekly_Wages
      Month_4_Week_4
      Month_4_Week_4_Sun
      Month_4_Week_4_Mon
      Month_4_Week_4_Tue
      Month_4_Week_4_Wed
      Month_4_Week_4_Thu
      Month_4_Week_4_Fri
      Month_4_Week_4_Sat
      Month_4_Week_4_Total_Hours
      Month_4_Week_4_Weekly_Wages
      Month_4_Total_Sun
      Month_4_Total_Mon
      Month_4_Total_Tue
      Month_4_Total_Wed
      Month_4_Total_Thu
      Month_4_Total_Fri
      Month_4_Total_Sat
      Month_4_Total_Hours
      Month_4_Total_Weekly_Wages
      Student_Signature_Form_404
      Student_Signature_Date_Form_404
      Supervisor_Signature_Form_404
      Supervisor_Signature_Date_Form_404
      Date_Form_420
      Relations_With_Others
      Attitude_Application_To_Work
      Judgement
      Dependability
      Ability_To_Learn
      Quality_Of_Work
      Overall_Performance
      Attendance
      Punctuality
      Remarks
      Report_Discussed_With_Student
      Student_Signature_Form_420
      Student_Signature_Date_Form_420
      Supervisor_Signature_Form_420
      Supervisor_Signature_Date_Form_420
      Supervisor_Name
      Rating_Of_Employer
      Rating_Of_Work_Exp
      Relation_To_CMPS_Studies
      Rating_Of_Learning_Of_Internship
      Would_You_Repeat
      Would_You_Repeat_Explain
      Would_You_Recommend
      Would_You_Recommend_Explain
      Additional_Content_For_Curriculum
      Any_Additional_Comments
      Student_Signature_Form_405
      Student_Signature_Date_Form_405
      Week_1
      Week_1_Activities
      Week_1_Hours
      Week_2
      Week_2_Activities
      Week_2_Hours
      Week_3
      Week_3_Activities
      Week_3_Hours
      Week_4
      Week_4_Activities
      Week_4_Hours
      Week_5
      Week_5_Activities
      Week_5_Hours
      Week_6
      Week_6_Activities
      Week_6_Hours
      Week_7
      Week_7_Activities
      Week_7_Hours
      Total_Hours
      Form_401
      Form_402
      Form_403
      Form_404
      Form_405
      Form_420
      Form_410
      sup_sig_date_form_402
      compl_sup_sig_form_403
      compl_sup_sig_date_form_403
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInternForm = /* GraphQL */ `
  mutation DeleteInternForm(
    $input: DeleteInternFormInput!
    $condition: ModelInternFormConditionInput
  ) {
    deleteInternForm(input: $input, condition: $condition) {
      id
      Student_Name
      W_Num
      Student_Cell_Num
      Student_Home_Phone
      Student_Work_Number
      Student_Present_Address
      Student_Permanent_Address
      Student_Email
      Degree
      Semester_Hours_Earned
      Major_GPA
      Expected_Grad_Date
      Company_Name
      Company_Address
      Name_and_Title_Immediate_Supervisor
      Supervisor_Email
      Supervisor_Phone
      Proposed_Start
      Proposed_End
      Employed_Full
      Job_Title
      Job_Responsibilities
      Student_Signature_Form_401
      Student_Signature_Date_Form_401
      Date_Rec_Dep
      Action_Taken
      Reason
      Faculty_Signature_Form_401
      Faculty_Signature_Date_Form_401
      Dep_Head_Signature_Form_401
      Dep_Head_Signature_Date_Form_401
      Dean_Signature_Form_401
      Dean_Signature_Date_Form_401
      Semester_And_Year
      Hours_Per_Week
      Employer_Signature_Form_402
      Employer_City
      Employer_State
      Employer_Zip
      Student_Signature_Form_402
      Student_Signature_Date_Form_402
      Student_Will_Accomplish_1
      Supervisor_Rating_1
      Student_Will_Accomplish_2
      Supervisor_Rating_2
      Student_Will_Accomplish_3
      Supervisor_Rating_3
      Student_Signature_Form_403
      Student_Signature_Date_Form_403
      Employer_Signature_Form_403
      Employer_Signature_Date_Form_403
      Intern_Cord_Signature_Form_403
      Intern_Cord_Signature_Date_Form_403
      Total_Hours_For_Semester
      Total_Wages_For_Deduction
      Month_1
      Month_1_Week_1
      Month_1_Week_1_Sun
      Month_1_Week_1_Mon
      Month_1_Week_1_Tue
      Month_1_Week_1_Wed
      Month_1_Week_1_Thu
      Month_1_Week_1_Fri
      Month_1_Week_1_Sat
      Month_1_Week_1_Total_Hours
      Month_1_Week_1_Weekly_Wages
      Month_1_Week_2
      Month_1_Week_2_Sun
      Month_1_Week_2_Mon
      Month_1_Week_2_Tue
      Month_1_Week_2_Wed
      Month_1_Week_2_Thu
      Month_1_Week_2_Fri
      Month_1_Week_2_Sat
      Month_1_Week_2_Total_Hours
      Month_1_Week_2_Weekly_Wages
      Month_1_Week_3
      Month_1_Week_3_Sun
      Month_1_Week_3_Mon
      Month_1_Week_3_Tue
      Month_1_Week_3_Wed
      Month_1_Week_3_Thu
      Month_1_Week_3_Fri
      Month_1_Week_3_Sat
      Month_1_Week_3_Total_Hours
      Month_1_Week_3_Weekly_Wages
      Month_1_Week_4
      Month_1_Week_4_Sun
      Month_1_Week_4_Mon
      Month_1_Week_4_Tue
      Month_1_Week_4_Wed
      Month_1_Week_4_Thu
      Month_1_Week_4_Fri
      Month_1_Week_4_Sat
      Month_1_Week_4_Total_Hours
      Month_1_Week_4_Weekly_Wages
      Month_1_Total_Sun
      Month_1_Total_Mon
      Month_1_Total_Tue
      Month_1_Total_Wed
      Month_1_Total_Thu
      Month_1_Total_Fri
      Month_1_Total_Sat
      Month_1_Total_Hours
      Month_1_Total_Weekly_Wages
      Month_2
      Month_2_Week_1
      Month_2_Week_1_Sun
      Month_2_Week_1_Mon
      Month_2_Week_1_Tue
      Month_2_Week_1_Wed
      Month_2_Week_1_Thu
      Month_2_Week_1_Fri
      Month_2_Week_1_Sat
      Month_2_Week_1_Total_Hours
      Month_2_Week_1_Weekly_Wages
      Month_2_Week_2
      Month_2_Week_2_Sun
      Month_2_Week_2_Mon
      Month_2_Week_2_Tue
      Month_2_Week_2_Wed
      Month_2_Week_2_Thu
      Month_2_Week_2_Fri
      Month_2_Week_2_Sat
      Month_2_Week_2_Total_Hours
      Month_2_Week_2_Weekly_Wages
      Month_2_Week_3
      Month_2_Week_3_Sun
      Month_2_Week_3_Mon
      Month_2_Week_3_Tue
      Month_2_Week_3_Wed
      Month_2_Week_3_Thu
      Month_2_Week_3_Fri
      Month_2_Week_3_Sat
      Month_2_Week_3_Total_Hours
      Month_2_Week_3_Weekly_Wages
      Month_2_Week_4
      Month_2_Week_4_Sun
      Month_2_Week_4_Mon
      Month_2_Week_4_Tue
      Month_2_Week_4_Wed
      Month_2_Week_4_Thu
      Month_2_Week_4_Fri
      Month_2_Week_4_Sat
      Month_2_Week_4_Total_Hours
      Month_2_Week_4_Weekly_Wages
      Month_2_Total_Sun
      Month_2_Total_Mon
      Month_2_Total_Tue
      Month_2_Total_Wed
      Month_2_Total_Thu
      Month_2_Total_Fri
      Month_2_Total_Sat
      Month_2_Total_Hours
      Month_2_Total_Weekly_Wages
      Month_3
      Month_3_Week_1
      Month_3_Week_1_Sun
      Month_3_Week_1_Mon
      Month_3_Week_1_Tue
      Month_3_Week_1_Wed
      Month_3_Week_1_Thu
      Month_3_Week_1_Fri
      Month_3_Week_1_Sat
      Month_3_Week_1_Total_Hours
      Month_3_Week_1_Weekly_Wages
      Month_3_Week_2
      Month_3_Week_2_Sun
      Month_3_Week_2_Mon
      Month_3_Week_2_Tue
      Month_3_Week_2_Wed
      Month_3_Week_2_Thu
      Month_3_Week_2_Fri
      Month_3_Week_2_Sat
      Month_3_Week_2_Total_Hours
      Month_3_Week_2_Weekly_Wages
      Month_3_Week_3
      Month_3_Week_3_Sun
      Month_3_Week_3_Mon
      Month_3_Week_3_Tue
      Month_3_Week_3_Wed
      Month_3_Week_3_Thu
      Month_3_Week_3_Fri
      Month_3_Week_3_Sat
      Month_3_Week_3_Total_Hours
      Month_3_Week_3_Weekly_Wages
      Month_3_Week_4
      Month_3_Week_4_Sun
      Month_3_Week_4_Mon
      Month_3_Week_4_Tue
      Month_3_Week_4_Wed
      Month_3_Week_4_Thu
      Month_3_Week_4_Fri
      Month_3_Week_4_Sat
      Month_3_Week_4_Total_Hours
      Month_3_Week_4_Weekly_Wages
      Month_3_Total_Sun
      Month_3_Total_Mon
      Month_3_Total_Tue
      Month_3_Total_Wed
      Month_3_Total_Thu
      Month_3_Total_Fri
      Month_3_Total_Sat
      Month_3_Total_Hours
      Month_3_Total_Weekly_Wages
      Month_4
      Month_4_Week_1
      Month_4_Week_1_Sun
      Month_4_Week_1_Mon
      Month_4_Week_1_Tue
      Month_4_Week_1_Wed
      Month_4_Week_1_Thu
      Month_4_Week_1_Fri
      Month_4_Week_1_Sat
      Month_4_Week_1_Total_Hours
      Month_4_Week_1_Weekly_Wages
      Month_4_Week_2
      Month_4_Week_2_Sun
      Month_4_Week_2_Mon
      Month_4_Week_2_Tue
      Month_4_Week_2_Wed
      Month_4_Week_2_Thu
      Month_4_Week_2_Fri
      Month_4_Week_2_Sat
      Month_4_Week_2_Total_Hours
      Month_4_Week_2_Weekly_Wages
      Month_4_Week_3
      Month_4_Week_3_Sun
      Month_4_Week_3_Mon
      Month_4_Week_3_Tue
      Month_4_Week_3_Wed
      Month_4_Week_3_Thu
      Month_4_Week_3_Fri
      Month_4_Week_3_Sat
      Month_4_Week_3_Total_Hours
      Month_4_Week_3_Weekly_Wages
      Month_4_Week_4
      Month_4_Week_4_Sun
      Month_4_Week_4_Mon
      Month_4_Week_4_Tue
      Month_4_Week_4_Wed
      Month_4_Week_4_Thu
      Month_4_Week_4_Fri
      Month_4_Week_4_Sat
      Month_4_Week_4_Total_Hours
      Month_4_Week_4_Weekly_Wages
      Month_4_Total_Sun
      Month_4_Total_Mon
      Month_4_Total_Tue
      Month_4_Total_Wed
      Month_4_Total_Thu
      Month_4_Total_Fri
      Month_4_Total_Sat
      Month_4_Total_Hours
      Month_4_Total_Weekly_Wages
      Student_Signature_Form_404
      Student_Signature_Date_Form_404
      Supervisor_Signature_Form_404
      Supervisor_Signature_Date_Form_404
      Date_Form_420
      Relations_With_Others
      Attitude_Application_To_Work
      Judgement
      Dependability
      Ability_To_Learn
      Quality_Of_Work
      Overall_Performance
      Attendance
      Punctuality
      Remarks
      Report_Discussed_With_Student
      Student_Signature_Form_420
      Student_Signature_Date_Form_420
      Supervisor_Signature_Form_420
      Supervisor_Signature_Date_Form_420
      Supervisor_Name
      Rating_Of_Employer
      Rating_Of_Work_Exp
      Relation_To_CMPS_Studies
      Rating_Of_Learning_Of_Internship
      Would_You_Repeat
      Would_You_Repeat_Explain
      Would_You_Recommend
      Would_You_Recommend_Explain
      Additional_Content_For_Curriculum
      Any_Additional_Comments
      Student_Signature_Form_405
      Student_Signature_Date_Form_405
      Week_1
      Week_1_Activities
      Week_1_Hours
      Week_2
      Week_2_Activities
      Week_2_Hours
      Week_3
      Week_3_Activities
      Week_3_Hours
      Week_4
      Week_4_Activities
      Week_4_Hours
      Week_5
      Week_5_Activities
      Week_5_Hours
      Week_6
      Week_6_Activities
      Week_6_Hours
      Week_7
      Week_7_Activities
      Week_7_Hours
      Total_Hours
      Form_401
      Form_402
      Form_403
      Form_404
      Form_405
      Form_420
      Form_410
      sup_sig_date_form_402
      compl_sup_sig_form_403
      compl_sup_sig_date_form_403
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
